import $ from 'jquery';
import 'what-input';
import 'tilt.js';
import Swiper from 'swiper';
import imagesLoaded from 'imagesloaded';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
// require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';
import Slideshow from './lib/slideshow';
import QuickStudies from './lib/quickstudies';
import Hubspot from './lib/hubspot';

$(document).foundation();

var slideshow = null;
var quickstudies = null;
var hubspot = new Hubspot;
hubspot.trackEvent('loaded', 'begin', 'app status');
var queue = [];
var queueIndex = 0;

function initialize() {
	$('#loading').fadeOut(250);
	quickstudies = new QuickStudies( slideshow, hubspot );
	quickstudies.onDomReady();
	slideshow.onDomReady();
	hubspot.trackEvent('loaded', 'complete', 'app status');
}

function requestQuickStudies( queueIndex ) {
	$.ajax({
		url: queue[queueIndex],
		success: function( response ) {
			$('.quick-study-container').append( response );

			queueIndex++;
			if( queue[queueIndex] != undefined ) {
				requestQuickStudies(queueIndex);
			} else {
				$('.swiper-container').each( ( index, ele ) => {
					new Swiper( ele, {
						loop: true,
						effect: 'fade',
						simulateTouch: false,
						autoplay: {
							delay: 7000
						},
						pagination: {
							el: $(ele).children('.swiper-pagination'),
							type: 'bullets',
							clickable: true
						}
					});
				});

				var imgLoad = imagesLoaded( '.quick-study-container', initialize );
				let count = 0;
				imgLoad.on( 'progress', function( instance, image ) {
					count++;
					let pct = (( count / $('.quick-study-container img').length ) * 100).toFixed( 0 );
					$('#loading .progress').attr('aria-valuenow', pct);
					$('#loading .progress-meter').width( pct + '%' );
					$('#loading .progress-meter-text').text( pct + '%' );
				});
			}
		}
	});
};

window.debounce = function( func, wait, immediate ) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

function setupMenu() {
	let htmlString = '<li><a href="#" class="quick-study-nav" data-study="home">Home</a></li>';
	for( let i = 0; i < hubspot.quickstudies.length; i++ ) {
		queue.push( '/quickstudy-'+ hubspot.quickstudies[i] +'.html' );
		if( hubspot.quickstudies[i] === 'jfm' ) {
			htmlString += '<li><a href="#" class="quick-study-nav" data-study="jfm">JUST FOR MEN</a></li>';
		} else {
			htmlString += '<li><a href="#" class="quick-study-nav" data-study="'+ hubspot.quickstudies[i] +'">'+ hubspot.quickstudies[i].toUpperCase() +'</a></li>';
		}
	}
	htmlString += '<li><a href="#" class="small" data-open="contactModal">Contact Us</a></li>';
	htmlString += '<li><a href="https://ampagency.com" target="_blank" class="small">Main Site</a></li>';
	$('#offCanvas .menu').append(htmlString);
}

// Call before page renders
$(document).ready( function() {
	var identify = hubspot.identify();
	identify.done( function( response ) {
		hubspot.apiResponse( response );
		setupMenu();
		slideshow = new Slideshow( hubspot );
		slideshow.constructHTML();
		requestQuickStudies(queueIndex);
	});
});

$(document).on('click', 'a[href="https://ampagency.com"]', function() {
	hubspot.trackEvent('click', 'AMP home page', 'outbound link');
});

$(document).on('click', '.quick-study-nav, .slideshow-headline-ctn small', function( evt ) {
	evt.preventDefault();
	$('#offCanvas').foundation('close');
	quickstudies.pauseVideos();

	if( $(this).data('study') === 'home' && !quickstudies.activeStudy ) {
		return;
	} else if( $(this).data('study') !== 'home' && quickstudies.activeStudy == $(this).data('study') ) {
		return;
	}

	if( $(this).data('study') === 'home' ) {
		slideshow.isActive = true;
		slideshow.time = 0;
		quickstudies.backToHome();
		slideshow.startAutoplay();
	} else {
		slideshow.isActive = false;
		hubspot.trackPage( $(this).data('study') );
		slideshow.stopAutoplay();
		if( quickstudies.activeStudy ) {
			quickstudies.navigateBetweenQuickstudies( $(this).data('study') );
		} else {
			quickstudies.goToQuickStudy( $(this).data('study') );
		}
	}
});

if( window.innerWidth < window.innerHeight ) {
	$('.portrait-prompt').show();
	$('.portrait-prompt').addClass('shown');
	hubspot.trackEvent('loaded', 'portrait view', 'app status');
} else {
	hubspot.trackEvent('loaded', 'landscape view', 'app status');
}

$('.portrait-prompt .button').click( function( evt ) {
	evt.preventDefault();
	$('.portrait-prompt').fadeOut();
	hubspot.trackEvent('loaded', 'switched from portrait to landscape', 'app status');
});

$(document).on( 'click', '#offCanvas .menu a[data-open="contactModal"], #header .menu .contact-button', function() {
	$('#offCanvas').foundation('close');
	hubspot.trackEvent('contact', 'open', 'nav');
});

$(document).on( 'click', 'a[href^="tel:"]', function() {
	hubspot.trackEvent('click', 'AMP telephone number', 'outbound link');
});