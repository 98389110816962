'use strict';

import $ from 'jquery';
import SwipeListener from 'swipe-listener';
import { TweenLite, TimelineMax, CSSPlugin } from "gsap/all";

class QuickStudies {

	constructor( slideshow, hubspot ) {
		this.slideshow = slideshow;
		this.hubspot = hubspot;

		this.activeStudy = null;
		this.slides = [];
		this.activeSlideIndex = null;
		this.slideTransitionSpeed = 1;
		this.timeline = null;
		this.slider = null;
		this.videoIsPlaying = false;
	}

	constructTimeline() {
		this.timeline = new TimelineMax();
		this.timeline
			.addLabel('home', 0)
			.to( '.quick-study[data-study="'+ this.activeStudy +'"]', this.slideTransitionSpeed, {
				x: '-100%'
			}, this.slideTransitionSpeed * 0)
			.to( '.quick-study-subnav-bullet:not([data-slide="1"])', this.slideTransitionSpeed, {
				background: 'url(./assets/img/bullet-quickstudy-inactive.png) no-repeat center'
			}, this.slideTransitionSpeed * 0)
			.to( '.quick-study-subnav-bullet[data-slide="1"]', this.slideTransitionSpeed, {
				background: 'url(./assets/img/bullet-quickstudy-active.png) no-repeat center'
			}, this.slideTransitionSpeed * 0)
			.addLabel('slide1')
			.to( '.quick-study[data-study="'+ this.activeStudy +'"] .quick-study-slide[data-slide="1"]', this.slideTransitionSpeed, {
				x: '-100%'
			}, this.slideTransitionSpeed * 1)
			.to( '.quick-study-subnav-bullet:not([data-slide="2"])', this.slideTransitionSpeed, {
				background: 'url(./assets/img/bullet-quickstudy-inactive.png) no-repeat center'
			}, this.slideTransitionSpeed * 1)
			.to( '.quick-study-subnav-bullet[data-slide="2"]', this.slideTransitionSpeed, {
				background: 'url(./assets/img/bullet-quickstudy-active.png) no-repeat center'
			}, this.slideTransitionSpeed * 1)
			.addLabel('slide2')
			.to( '.quick-study[data-study="'+ this.activeStudy +'"] .quick-study-slide[data-slide="3"]', this.slideTransitionSpeed, {
				x: '-100%'
			}, this.slideTransitionSpeed * 2)
			.to( '.quick-study-subnav-bullet:not([data-slide="3"])', this.slideTransitionSpeed, {
				background: 'url(./assets/img/bullet-quickstudy-inactive.png) no-repeat center'
			}, this.slideTransitionSpeed * 2)
			.to( '.quick-study-subnav-bullet[data-slide="3"]', this.slideTransitionSpeed, {
				background: 'url(./assets/img/bullet-quickstudy-active2.png) no-repeat center'
			}, this.slideTransitionSpeed * 2)
			.addLabel('slide3')
			.pause(0);
	}

	onDomReady() {
		this.constructHTML();
		this.createEvents();
	}

	constructHTML() {
		$('.quick-study').each( ( index, ele ) => {
			let numSlides = $(ele).children('.quick-study-slide').length;
			let htmlString = '<div class="quick-study-subnav">';
			for( let i = 0; i < numSlides; i++ ) {
				if( i === 0 ) {
					htmlString += '<div class="quick-study-subnav-home">Home</div>';
				}
				htmlString += '<div class="quick-study-subnav-bullet" data-slide="'+ (i + 1) +'"></div>';
			}
			htmlString += '<div class="quick-study-subnav-bullet quick-study-subnav-bullet-contact"></div></div><div class="quick-study-arrow-prev"></div><div class="quick-study-arrow-next"></div>';
			$(ele).append(htmlString);
		});
	}

	openContactModal() {
		this.hubspot.trackPage('contact');
		this.hubspot.trackEvent('contact', 'open', 'quickstudy slider');
		$('#contactModal').foundation('open');
	}

	createEvents() {
		$(document).on( 'click', '.quick-study-subnav-bullet:not(.quick-study-subnav-bullet-active, .quick-study-subnav-bullet-contact), .quick-study-arrow-prev, .quick-study-arrow-next, .quick-study-subnav-home', this.navigate.bind(this) );
		$(document).on( 'click', '.quick-study-subnav-bullet.quick-study-subnav-bullet-contact', this.openContactModal.bind(this) );
		$(document).on( 'keydown', this.navigateWithKeys.bind(this) );
		$(document).on( 'click', 'button.results-toggle', this.toggleResults.bind(this) );
		let studies = document.querySelector('.quick-study');
		SwipeListener( studies );
		studies.addEventListener( 'swipe', this.navigateWithSwipe.bind(this) );
		$('video').on( 'play', function() {
			this.videoIsPlaying = true;
			$('.quick-study-slider').each( function() {
				let swiper = $(this)[0].swiper;
				swiper.detachEvents();
				swiper.autoplay.stop();
			});
		}.bind(this) );
		$('video').on( 'pause ended error', function() {
			this.videoIsPlaying = false;
			$('.quick-study-slider').each( function() {
				let swiper = $(this)[0].swiper;
				swiper.attachEvents();
				swiper.autoplay.start();
			});
		}.bind(this) );
	}

	pauseVideos() {
		$('video').each( function() {
			this.pause();
		});
	}

	navigate( evt ) {
		if( this.videoIsPlaying ) {
			this.pauseVideos();
		}

		if( $(evt.target).hasClass('quick-study-arrow-next') ) {
			switch( this.activeSlideIndex ) {
				case 0:
					this.activeSlideIndex++;
					this.timeline.tweenTo('slide2');
					break;

				case 1:
					this.activeSlideIndex++;
					this.timeline.tweenTo('slide3');
					break;

				case 2:
					this.openContactModal();
					break;

				default:
					break;
			}
		} else if( $(evt.target).hasClass('quick-study-arrow-prev') ) {
			switch( this.activeSlideIndex ) {
				case 0:
					this.timeline.reverse();
					this.activeSlideIndex = null;
					this.activeStudy = null;
					this.slideshow.isActive = true;
					slideshow.time = 0;
					break;

				case 1:
					this.activeSlideIndex--;
					this.timeline.tweenTo('slide1');
					break;

				case 2:
					this.activeSlideIndex--;
					this.timeline.tweenTo('slide2');
					break;

				default:
					break;
			}
		} else if( $(evt.target).hasClass('quick-study-subnav-home') ) {
			if( this.activeSlideIndex > 0 ) {
				this.timeline.totalDuration(this.slideTransitionSpeed).reverse();
			} else {
				this.timeline.reverse();
			}
			this.activeSlideIndex = null;
			this.activeStudy = null;
			setTimeout( () => {
				this.slideshow.isActive = true;
				slideshow.time = 0;
			}, 300 );
		} else {
			let goTo = $(evt.target).data('slide');
			if( Math.abs( this.activeSlideIndex + 1 - parseInt( goTo ) ) > 1 ) {
				this.activeSlideIndex = parseInt(goTo - 1);
				this.timeline.totalDuration(this.slideTransitionSpeed).tweenTo('slide'+ goTo, {
					onComplete: () => {
						this.timeline.totalDuration( this.slideTransitionSpeed * 3 );
					},
					onCompleteScope: this
				});
			} else {
				this.activeSlideIndex = parseInt(goTo - 1);
				this.timeline.tweenTo('slide'+ goTo);
			}
		}

		// check for image slider & update
		this.resetSliderIfExists();
		
		if( this.activeStudy ) {
			this.changeLogo( $('.quick-study[data-study="'+ this.activeStudy +'"] .quick-study-slide[data-slide="'+ (this.activeSlideIndex + 1) +'"]').data('logo') );
			$('.quick-study[data-study="'+ this.activeStudy +'"]').attr('data-slide-active', this.activeSlideIndex);
		} else {
			this.changeLogo( '2color' );
			$('.quick-study').attr('data-slide-active', '');
		}
		
		if( this.activeSlideIndex === 2 ) {
			$('.menu-icon').addClass('white');
		} else {
			$('.menu-icon').removeClass('white');
		}

		if( !$('#contactModal').hasClass('fade-in') && $('#contactmodal').css('display') !== 'block' ) {
			this.hubspot.trackEvent('slide change', 'slide '+ (this.activeSlideIndex + 1) +' on '+ this.activeStudy, 'quickstudy slider');
		}
	}

	navigateWithSwipe( evt ) {
		if( this.videoIsPlaying ) {
			this.pauseVideos();
		}

		if( this.activeStudy ) {
			if( evt.detail.directions.left ) {
				this.hubspot.trackEvent('navigation', 'next with swipe', 'quickstudy slider');
				if( this.activeSlideIndex === 2 ) {
					this.openContactModal();
				} else {
					this.navigate({ target: $('.quick-study-arrow-next') });
				}
			} else if( evt.detail.directions.right ) {
				this.hubspot.trackEvent('navigation', 'previous with swipe', 'quickstudy slider');
				if( $('#contactModal[aria-hidden="false"]').length ) {
					$('#contactModal').foundation('close');
					return;
				}
				if( this.activeSlideIndex === 0 ) {
					this.backToHome();
				} else {
					this.navigate({ target: $('.quick-study-subnav-bullet[data-slide="'+ this.activeSlideIndex +'"]') });
				}
			}
		}
	}

	navigateWithKeys( evt ) {
		if( this.videoIsPlaying ) {
			this.pauseVideos();
		}

		if( this.activeStudy ) {
			if( evt.keyCode === 39 ) {
				this.hubspot.trackEvent('navigation', 'next with keyboard', 'quickstudy slider');
				if( this.activeSlideIndex === 2 ) {
					this.openContactModal();
				} else {
					this.navigate({ target: $('.quick-study-arrow-next') });
				}
			} else if( evt.keyCode === 37 ) {
				this.hubspot.trackEvent('navigation', 'previous with keyboard', 'quickstudy slider');
				if( $('#contactModal[aria-hidden="false"]').length ) {
					$('#contactModal').foundation('close');
					return;
				}
				if( this.activeSlideIndex === 0 ) {
					this.backToHome();
				} else {
					this.navigate({ target: $('.quick-study-subnav-bullet[data-slide="'+ this.activeSlideIndex +'"]') });
				}
			}
		}
	}

	navigateBetweenQuickstudies( newStudy ) {
		if( this.videoIsPlaying ) {
			this.pauseVideos();
		}

		TweenLite.to( '.quick-study[data-study="'+ this.activeStudy +'"]', 0.5, {
			y: '-100%',
			onComplete: function() {
				this.backToHome();
				this.goToQuickStudy( newStudy );
			},
			onCompleteScope: this
		});
	}

	resetSliderIfExists() {
		let $potentialSlider = $('.quick-study[data-study="'+ this.activeStudy +'"] .quick-study-slide[data-slide="'+ (this.activeSlideIndex + 1) +'"] .quick-study-slider');
		if( $potentialSlider.length ) {
			$potentialSlider[0].swiper.slideToLoop(0, 0, false);
			$potentialSlider[0].swiper.update();
			$potentialSlider[0].swiper.autoplay.start();
		}
	}

	backToHome() {
		this.hubspot.trackEvent('navigation', 'home', 'quickstudy slider');
		this.navigate({ target: $('.quick-study-subnav-home') });
	}

	toggleResults( evt ) {
		let $this = $(evt.target);
		switch( $this.attr('data-display') ) {
			case 'results':
				// show story
				TweenLite.to( '.quick-study[data-study="'+ this.activeStudy +'"] .quick-study-slide[data-slide="'+ (this.activeSlideIndex + 1) +'"] p.results', 0.28, {
					opacity: 0,
					onComplete: () => {
						$('.quick-study[data-study="'+ this.activeStudy +'"] .quick-study-slide[data-slide="'+ (this.activeSlideIndex + 1) +'"] p.results').css('display', 'none');
						TweenLite.to( '.quick-study[data-study="'+ this.activeStudy +'"] .quick-study-slide[data-slide="'+ (this.activeSlideIndex + 1) +'"] p.story', 0.28, {
							opacity: 1,
							display: 'block'
						});
					},
					onCompleteScope: this
				});
				$this.text('See Results');
				$this.attr('data-display', 'story');
				this.hubspot.trackEvent('quickstudies toggle', 'activated story on slide '+ (this.activeSlideIndex + 1) +' on '+ this.activeStudy, 'results/story');
				break;

			case 'story':
				// show results
				TweenLite.to( '.quick-study[data-study="'+ this.activeStudy +'"] .quick-study-slide[data-slide="'+ (this.activeSlideIndex + 1) +'"] p.story', 0.28, {
					opacity: 0,
					onComplete: () => {
						$('.quick-study[data-study="'+ this.activeStudy +'"] .quick-study-slide[data-slide="'+ (this.activeSlideIndex + 1) +'"] p.story').css('display', 'none');
						TweenLite.to( '.quick-study[data-study="'+ this.activeStudy +'"] .quick-study-slide[data-slide="'+ (this.activeSlideIndex + 1) +'"] p.results', 0.28, {
							opacity: 1,
							display: 'block'
						});
					},
					onCompleteScope: this
				});
				$this.text('See Story');
				$this.attr('data-display', 'results');
				this.hubspot.trackEvent('quickstudies toggle', 'activated results on slide '+ (this.activeSlideIndex + 1) +' on '+ this.activeStudy, 'results/story');
				break;
		
			default:
				break;
		}
	}

	changeLogo( color ) {
		$('.logos-active-ctn img:visible').hide();
		$('.logos-active-ctn img[src*="'+ color +'"]').show();
	}

	goToQuickStudy( studyName ) {
		this.hubspot.trackEvent('activated', studyName, 'quickstudy');

		if( this.videoIsPlaying ) {
			this.pauseVideos();
		}

		let studyStyleStr = $('.quick-study[data-study="'+ studyName + '"]').attr('style');
		if( studyStyleStr && studyStyleStr.indexOf('translate(-100%, -100%)') ) {
			TweenLite.set( '.quick-study[data-study="'+ studyName + '"]', {
				y: '0%'
			});
		}

		this.activeStudy = studyName;
		this.activeSlideIndex = 0;
		this.resetSliderIfExists();
		this.slides = $('.quick-study[data-study="'+ this.activeStudy + '"] .quick-study-slide');
		this.constructTimeline();
		this.timeline.tweenTo('slide1');
	}
}

export default QuickStudies;