'use strict';

import $ from 'jquery';
import SwipeListener from 'swipe-listener';
import { TweenLite, CSSPlugin } from "gsap/all";

class Slideshow {

	constructor( hubspot ) {
		this.hubspot = hubspot;

		let potentialSlides = {
			beautycon: {
				logo: './assets/img/beautycon/logo.png',
				src: './assets/img/beautycon/flat.jpg',
				study: 'beautycon',
				layerBack: './assets/img/beautycon/back.jpg',
				layerMid: './assets/img/beautycon/mid.png',
				layerFront: './assets/img/beautycon/front.png',
				alt: '<span class="show-for-medium">As a preferred vendor to Beautycon for over 5 years, we help brands stand out from the crowd</span><span class="show-for-small-only">As a preferred vendor for 5+ years, we help brands stand out</span>',
				center: 80
			},
			jfm: {
				logo: './assets/img/jfm/logo.png',
				src: './assets/img/jfm/flat.jpg',
				study: 'jfm',
				layerBack: './assets/img/jfm/flat.jpg',
				alt: 'Be The Better Man',
				center: 50
			},
			maruchan: {
				logo: './assets/img/maruchan/logo.png',
				src: './assets/img/maruchan/flat.jpg',
				study: 'maruchan',
				layerBack: './assets/img/maruchan/flat.jpg',
				alt: 'Bringing smiles to the table',
				center: 70
			},
			mission: {
				logo: './assets/img/mission/logo.png',
				src: './assets/img/mission/flat.jpg',
				study: 'mission',
				layerBack: './assets/img/mission/back.jpg',
				layerMid: './assets/img/mission/mid.png',
				layerFront: './assets/img/mission/front.png',
				alt: 'Our instant-win sweepstakes helped cook up the best Cinco de Mayo yet.',
				center: 80
			},
			patagonia: {
				logo: './assets/img/patagonia/logo.png',
				src: './assets/img/patagonia/flat.jpg',
				study: 'patagonia',
				layerBack: './assets/img/patagonia/back.jpg',
				layerMid: './assets/img/patagonia/mid.png',
				layerFront: './assets/img/patagonia/front.png',
				alt: 'We stitched together one epic road trip',
				center: 62
			},
			puma: {
				logo: './assets/img/puma/logo.png',
				src: './assets/img/puma/flat.jpg',
				study: 'puma',
				layerBack: './assets/img/puma/flat.jpg',
				alt: 'Building an army of ass-kicking women',
				center: 65
			},
			virtue: {
				logo: './assets/img/virtue/logo.png',
				src: './assets/img/virtue/flat.jpg',
				study: 'virtue',
				layerBack: './assets/img/virtue/flat.jpg',
				alt: 'Introducing the world to the next breakthrough in beauty',
				center: 70
			}
		};
		this.slides = [];
		this.tweens = [];

		for( let i = 0; i < this.hubspot.quickstudies.length; i++ ) {
			this.slides.push( potentialSlides[ this.hubspot.quickstudies[i] ] );
		}

		this.elements = {
			slideshow: document.getElementById('slideshow'),
			container: null,
			navbullets: [],
			slides: [],
			currentSlide: null,
			nextSlide: null
		};
		this.initialSlide = true;
		this.activeSlideIndex = 0;
		this.userSelectedIndex = null;
		this.isAnimating = false;
		this.slideTransitionComplete = {
			left: false,
			right: false
		};
		this.autoplayPaused = false;
		this.time = 0;
		this.timer = null;
		this.autoplayInterval = 7;
		this.isActive = true;
		this.transitionSpeed = 0.65;
	}

	constructHTML() {
		// set up containers
		let container = document.createElement('div');
		container.setAttribute('class', 'slideshow-container');
		let navigation = document.createElement('div');
		navigation.setAttribute('class', 'slideshow-navigation');

		// create slides
		for( let i = 0; i < this.slides.length; i++ ) {
			let slide = document.createElement('div');
			if( i === 0 ) {
				slide.setAttribute('class', 'slideshow-slide slideshow-slide-active '+ this.slides[i].study);
			} else {
				slide.setAttribute('class', 'slideshow-slide '+ this.slides[i].study);
			}

			// set up image containers
			let imgLeftCtn = document.createElement('div');
			imgLeftCtn.setAttribute('class', 'slideshow-img-left-ctn');
			imgLeftCtn.setAttribute('data-center', this.slides[i].center);
			let imgRightCtn = document.createElement('div');
			imgRightCtn.setAttribute('class', 'slideshow-img-right-ctn');
			imgRightCtn.setAttribute('data-center', this.slides[i].center);

			// set up images
			let imgLeft = $('<img class="slideshow-img-left" src="'+ this.slides[i].src +'" />');
			let imgRight = $('<img class="slideshow-img-right" src="'+ this.slides[i].src +'" />');

			// set up layers if needed
			let layerCtn = null;
			if( this.slides[i].hasOwnProperty('layerBack') ) {
				layerCtn = document.createElement('div');
				layerCtn.setAttribute('class', 'slideshow-layers');
				layerCtn.setAttribute('data-tilt', '');
				layerCtn.setAttribute('data-tilt-perspective', '2200');
				layerCtn.setAttribute('data-tilt-speed', '800');
				layerCtn.setAttribute('data-tilt-max', '3');
				layerCtn.setAttribute('data-center', this.slides[i].center);

				let background = document.createElement('img');
				background.setAttribute('class', 'slideshow-background');
				background.setAttribute('src', this.slides[i].layerBack);
				layerCtn.appendChild(background);

				if( this.slides[i].hasOwnProperty('layerMid') ) {
					let midground = document.createElement('img');
					midground.setAttribute('class', 'slideshow-mid');
					midground.setAttribute('src', this.slides[i].layerMid);
					layerCtn.appendChild(midground);
				}

				if( this.slides[i].hasOwnProperty('layerFront') ) {
					let frontground = document.createElement('img');
					frontground.setAttribute('class', 'slideshow-front');
					frontground.setAttribute('src', this.slides[i].layerFront);
					layerCtn.appendChild(frontground);
				}
			} else {
				imgRight.attr('data-tilt', '');
				imgRight.attr('data-tilt-perspective', '1300');
				imgRight.attr('data-tilt-speed', '400');
				imgRight.attr('data-tilt-max', '3');
				imgRight.attr('data-tilt-scale', '1.01');
			}

			// set up slide headlines
			let headlineCtn = document.createElement('div');
			headlineCtn.setAttribute('class', 'slideshow-headline-ctn');
			headlineCtn.appendChild( $('<h1><img class="logo" src="'+ this.slides[i].logo +'" alt="'+ this.slides[i].study +' logo" /><img src="./assets/img/accent-hero-triangle.png" />'+ this.slides[i].alt +'<small data-study="'+ this.slides[i].study +'">Read More</small></h1>')[0] );
			let headlineCtnRight = headlineCtn.cloneNode(true);

			// append elements
			imgLeftCtn.appendChild( imgLeft[0] );
			imgLeftCtn.appendChild( headlineCtn );
			imgRightCtn.appendChild( imgRight[0] );
			if( layerCtn ) {
				imgRightCtn.appendChild( layerCtn );
				layerCtn.appendChild( headlineCtnRight );
			} else {
				imgRightCtn.appendChild( headlineCtnRight );
			}
			slide.appendChild( imgLeftCtn );
			slide.appendChild( imgRightCtn );
			container.appendChild(slide);

			if( i === 0 ) {
				this.elements.currentSlide = $(slide);
			} else if( i === 1 ) {
				this.elements.nextSlide = $(slide);
			}
			this.elements.slides.push( $(slide) );

			let bullet = document.createElement('div');
			bullet.setAttribute('data-index', i);
			if( i === 0 ) {
				bullet.setAttribute('class', 'slideshow-navbullet slideshow-navbullet-active');
			} else {
				bullet.setAttribute('class', 'slideshow-navbullet');
			}
			navigation.appendChild(bullet);
			this.elements.navbullets.push( $(bullet) );
		}

		this.elements.container = container;
		this.elements.slideshow.appendChild(container);
		this.elements.slideshow.appendChild(navigation);
	}

	createEvents() {
		$(document).on( 'click', '.slideshow-navbullet:not(.slideshow-navbullet-active)', this.navigate.bind(this) );
		let slideshow = $('#slideshow')[0];
		SwipeListener( slideshow );
		slideshow.addEventListener( 'swipe', this.handleSwipeOrScroll.bind(this) );
		slideshow.onwheel = window.debounce( this.handleSwipeOrScroll.bind(this), 250, true );
		$(window).blur( this.handleBlur.bind(this) );
		$(window).focus( this.handleFocus.bind(this) );
		$(window).resize( this.handleResize );
		$(window).trigger('resize');
		$('[data-tilt]').tilt();
		$(document).on( 'keydown', this.navigateWithKeys.bind(this) );
	}

	onDomReady() {
		this.createEvents();
		this.transitionSlideIn();
	}

	handleBlur() {
		if( this.isAnimating ) {
			for( let i = 0; i < this.tweens.length; i++ ) {
				if( this.tweens[i].progress() < 1 ) {
					this.tweens[i].pause();
				}
			}
		}

		this.stopAutoplay();
	}

	handleFocus() {
		if( this.isAnimating ) {
			for( let i = 0; i < this.tweens.length; i++ ) {
				if( this.tweens[i].progress() < 1 ) {
					this.tweens[i].play();
				}
			}
		}

		this.resumeAutoplay();
	}

	handleResize() {
		if( window.innerWidth < window.innerHeight ) {
			$('.portrait-prompt:not(.shown)').show();
			$('.portrait-prompt:not(.shown)').addClass('shown');
		}

		let smallestImgWidth = null;
		$('#slideshow .slideshow-img-right').each( (i, ele) => {
			let width = $(ele).width();
			if( !smallestImgWidth || width < smallestImgWidth ) {
				smallestImgWidth = width;
			}
		});

		if( window.innerWidth > smallestImgWidth ) {
			$('body:not(.constrained)').addClass('constrained');
			$('body').width( smallestImgWidth );
			$('.quick-study').css({
				width: smallestImgWidth,
				left: window.innerWidth + ( (( smallestImgWidth - window.innerWidth ) / 2 ) - 1 )
			});
			$('#contactModal').css({
				width: smallestImgWidth + 2,
				left: (( smallestImgWidth - window.innerWidth ) / 2 ) - 1
			});
			$('.extra-bars').width( (( smallestImgWidth - window.innerWidth ) / -2 ) + 1 );
		} else {
			$('body.constrained').removeClass('constrained');
			$('body').removeAttr('style');
			$('.quick-study').css({
				width: '',
				left: ''
			});
			$('#contactModal').css({
				'width': '',
				'left': 0
			});
			$('.extra-bars').width( 0 );
		}

		$('#slideshow .slideshow-slide img').each( (i, ele) => {
			let $parent = $(ele).parent();
			if( $parent.data('center') && parseInt( $parent.data('center') ) === 50 ) {
				$(ele).css('margin-right', $parent.width() * -0.5 );
			}
		});
	}

	previousSlide() {
		if( this.isAnimating ) {
			return;
		}

		this.stopAutoplay();
		let active = parseInt( $('.slideshow-navbullet-active').data('index') );

		if( active === $('.slideshow-navbullet').length - 1 ) {
			active = 0;
		} else {
			active++;
		}

		this.userSelectedIndex = active;
		this.elements.nextSlide = this.elements.slides[this.userSelectedIndex];
		this.transitionSlideOut();
	}

	nextSlide() {
		if( this.isAnimating ) {
			return;
		}

		this.stopAutoplay();
		let active = parseInt( $('.slideshow-navbullet-active').data('index') );

		if( active === 0 ) {
			active = $('.slideshow-navbullet').length - 1;
		} else {
			active--;
		}

		this.userSelectedIndex = active;
		this.elements.nextSlide = this.elements.slides[this.userSelectedIndex];
		this.transitionSlideOut();
	}

	navigateWithKeys( evt ) {
		if( this.isActive ) {
			if( evt.keyCode === 39 ) {
				this.hubspot.trackEvent('navigation', 'previous with keyboard', 'slideshow');
				this.previousSlide();
			} else if( evt.keyCode === 37 ) {
				this.hubspot.trackEvent('navigation', 'next with keyboard', 'slideshow');
				this.nextSlide();
			}
		}
	}

	handleSwipeOrScroll( evt ) {
		if( this.isActive ) {
			if( evt && evt.type === 'swipe' ) {
				// touch
				if( evt.detail.directions.left || evt.detail.directions.bottom ) {
					this.hubspot.trackEvent('navigation', 'previous with swipe', 'slideshow');
					this.previousSlide();
				} else if( evt.detail.directions.right || evt.detail.directions.top ) {
					this.hubspot.trackEvent('navigation', 'next with swipe', 'slideshow');
					this.nextSlide();
				}
			} else {
				// scroll
				if( evt.deltaY > 0 ) {
					this.hubspot.trackEvent('navigation', 'previous with scroll', 'slideshow');
					this.previousSlide();
				}
				else if( evt.deltaY < 0 ) {
					this.hubspot.trackEvent('navigation', 'next with scroll', 'slideshow');
					this.nextSlide();
				}
			}
		}
	}

	navigate( event ) {
		if( this.isAnimating ) {
			return;
		}

		this.hubspot.trackEvent('navigation', 'clicked nav bullet', 'slideshow');
		this.stopAutoplay();
		this.userSelectedIndex = parseInt( $(event.currentTarget).data('index') );
		this.elements.nextSlide = this.elements.slides[this.userSelectedIndex];
		this.transitionSlideOut();
	}

	detectSlideTransitionComplete( transition ) {
		// console.log('slide transition complete', transition);
		switch( transition ) {
			case 'left':
				this.slideTransitionComplete.left = true;
				break;
			
			case 'right':
				this.slideTransitionComplete.right = true;
				break;

			default:
				break;
		}

		if( this.slideTransitionComplete.left && this.slideTransitionComplete.right ) {
			this.slideTransitionComplete.left = false;
			this.slideTransitionComplete.right = false;

			this.elements.currentSlide.removeClass('slideshow-slide-active');
			this.elements.navbullets[this.activeSlideIndex].removeClass('slideshow-navbullet-active');

			if( this.userSelectedIndex !== null ) {
				this.elements.currentSlide = this.elements.nextSlide;
				// console.log('transitioning from slide ' + this.activeSlideIndex + ' to slide ' + this.userSelectedIndex);
				this.activeSlideIndex = this.userSelectedIndex;
				if( this.activeSlideIndex === this.elements.slides.length - 1 ) {
					this.elements.nextSlide = this.elements.slides[0];
				} else {
					this.elements.nextSlide = this.elements.slides[this.activeSlideIndex + 1];
				}
				this.userSelectedIndex = null;
			} else if( this.activeSlideIndex === this.elements.slides.length - 1 ) {
				// console.log('transitioning from last slide to slide 0');
				this.activeSlideIndex = 0;
				this.elements.currentSlide = this.elements.slides[0];
				this.elements.nextSlide = this.elements.slides[1];
			} else {
				this.elements.currentSlide = this.elements.nextSlide;
				this.activeSlideIndex++;
				// console.log('transitioning from slide ' + this.activeSlideIndex + ' to slide ' + (this.activeSlideIndex + 1));
				this.elements.nextSlide = this.elements.slides[this.activeSlideIndex + 1];
			}

			this.elements.currentSlide.addClass('slideshow-slide-active');
			this.elements.navbullets[this.activeSlideIndex].addClass('slideshow-navbullet-active');
			this.transitionSlideIn();
		}
	}

	transitionSlideIn() {
		// console.log('begin slide transition in');
		let activeClass = this.elements.currentSlide.attr('class');
		activeClass = activeClass.replace('slideshow-slide', '').replace('slideshow-slide-active', '').replace(' ', '');
		this.hubspot.trackEvent('slide active', activeClass, 'slideshow');

		let leftIn = TweenLite.to( '.slideshow-slide-active .slideshow-img-left-ctn', this.transitionSpeed, {
			css: {
				y: '+=110%',
				opacity: 1,
				rotation: '0deg'
			},
			onComplete: function() {
				this.isAnimating = false;
			},
			onCompleteScope: this
		});

		let rightIn = TweenLite.to( '.slideshow-slide-active .slideshow-img-right-ctn', 1, {
			css: {
				y: '-=110%',
				opacity: 1,
				rotation: '0deg'
			}
		});

		this.tweens.push( leftIn, rightIn );
		this.time = 0;
		this.startAutoplay();
	}

	transitionSlideOut() {
		// console.log('begin slide transition out');
		this.isAnimating = true;
		this.tweens = [];

		let leftOut = TweenLite.to( '.slideshow-slide-active .slideshow-img-left-ctn', this.transitionSpeed, {
			css: {
				y: '-=110%',
				opacity: 0,
				rotation: '5deg'
			},
			onComplete: this.detectSlideTransitionComplete.bind(this),
			onCompleteParams: ['left'],
			onCompleteScope: this
		});

		let rightOut = TweenLite.to( '.slideshow-slide-active .slideshow-img-right-ctn', this.transitionSpeed, {
			css: {
				y: '+=110%',
				opacity: 0,
				rotation: '5deg'
			},
			onComplete: this.detectSlideTransitionComplete.bind(this),
			onCompleteParams: ['right'],
			onCompleteScope: this
		});

		this.tweens.push( leftOut, rightOut );
	}

	incrementTime() {
		if( !this.autoplayPaused ) {
			this.time++;

			if( this.time === this.autoplayInterval ) {
				this.time = 0;
				if( document.hasFocus() && !this.isAnimating ) {
					this.pauseAutoplay();
					this.transitionSlideOut();
				}
			}
		}
	}

	startAutoplay() {
		if( this.timer !== null ) {
			this.resumeAutoplay();
		} else {
			// console.log('starting autoplay');
			this.timer = setInterval( this.incrementTime.bind(this), 1000 );
		}
	}

	resumeAutoplay() {
		// console.log('resuming autoplay');
		this.autoplayPaused = false;
	}

	pauseAutoplay() {
		// console.log('pausing autoplay');
		this.autoplayPaused = true;
	}

	stopAutoplay() {
		// console.log('stop autoplay');
		clearInterval( this.timer );
		this.timer = null;
	}
}

export default Slideshow;