'use strict';

import $ from 'jquery';

class Hubspot {

	constructor() {
        this.userID = "";
        this.guid = location.pathname.replace('/', '');
        if(location.host === "localhost"){
           this.apiURL = location.href + 'assets/library/data.php?action=api';
        } else {
           this.apiURL = location.origin + '/assets/library/data.php?action=api';
        }
        this.quickstudies = ['beautycon', 'jfm', 'maruchan', 'mission', 'patagonia', 'puma', 'virtue'];
    }

    identify() {
        let _hsq = window._hsq = window._hsq || [];
        let apiRequest = this.apiURL;
        
        if(location.host !== "localhost" && this.guid !== ""){
            apiRequest = apiRequest + '&guid=' + this.guid;
        } else if(location.host === "localhost"){
            this.guid = "jonbish";
            apiRequest = apiRequest + '&guid=' + this.guid;
        } 

        if(this.guid !== ""){

        }

        return $.ajax({
            method: "GET",
						url: apiRequest
        });
		}

		apiResponse( response ) {
			if( typeof response === 'object' ) {
				console.log('user email', response.email);
				if(typeof response.email !== "undefined" && typeof response.studies !== "undefined"){
					$('input[name="full_name"]').val( response.name ).trigger('change');

					this.quickstudies = JSON.parse(response.studies);
					return this.quickstudies;
				}
			} else {
				console.warn('no user email received');
				return this.quickstudies;
			}
		}

    trackPage(page){
        let _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', page]);
				_hsq.push(['trackPageView']);

        gtag('config', 'UA-1678953-50', {'page_path': page});
    }

    trackEvent(action, value, category){
        let _hsq = window._hsq = window._hsq || [];
        _hsq.push(["trackEvent", {
            id: action,
            value: value
        }]);

        gtag('event', action, {
            'event_category' : category,
            'event_label' : value
        });
    }

}

export default Hubspot;